.ab-bg-img {
    position: relative;
    width: 100%;
}

.ab-title {
    position: absolute;
    top: 25%;
    left: 43%;
    color: white;
}

.blue {
    color: #253B80;
}

.heading {
    margin-top: 110px;
}

.circles {
    position: absolute;
    left: -46px;
    top: 97%;
}

.bg-banner {
    position: relative;
    margin-top: 110px;
}

.bg-banner-img {
    width: 153px;
    height: 20vh;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    bottom: 11%;
    z-index: -1;
}

.bg-content h3 {
    position: absolute;
    top: 13%;
    left: 43%;
}

.bg-content h5 {
    position: absolute;
    top: 28%;
    left: 28%;
}

.bg-content h5 span {
    opacity: 0.5;
}

.bg-content h4 {
    position: absolute;
    top: 37%;
    left: 42%;
}

.bg-content1 {
    position: absolute;
    top: 46%;
    left: 46%;
}

.btn button {
    border: none;
    padding: 20px 25px;
    background-color: #253B80;
    color: white;
    border-radius: 10px ;
    position: absolute;
    top: 62%;
    left: 45%;
}

.slider {
    display: flex;
    justify-content: space-between;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 15px;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

img.card-img-top.event-img {
    width: 100%;
    height: 187px;
    object-fit: cover;
}

/* .event-img {
    width: 100%;
    height: 187px;
    object-fit: cover;
} */

.card-text {
    display: flex;
}

.grey {
    color: black;
    opacity: 0.5;
}

.foot-c {
    width: 100%;
}
section.log-section {
    padding: 7em 0;
}

.text-wrap,
.login-wrap {
    width: 50%;
}

.form_tag_lg {
    width: 1000px !important;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
@media (max-width: 991.98px) {
    .text-wrap,
    .login-wrap {
        width: 100%;
        border-bottom: 1px solid gainsboro;
    }
}
@media (max-width: 769px) {
    .form_tag_lg {
        width: 100% !important;
    }
}

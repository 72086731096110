.filiterstartup {
  border-bottom: 1px solid gainsboro;
  display: flex;
  overflow-x: auto;
}
.applyBtn {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  /* border-right: 1px solid gainsboro; */
  /* border-left: 1px solid gainsboro; */
}

.applyBtn.act_span {
  color: white;
  background: #253b80;
  border: none;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #dc3545;
}
.font-16 {
  font-size: 16px;
}

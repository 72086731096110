html,
body {
  height: 100%;
  scroll-behavior: smooth;
  /* font-family: 'Lato', sans-serif; */

  /* font-family: 'Oswald', sans-serif; */
}
.menuBtn {
  font-family: "Montserrat", sans-serif !important;
}
iframe {
  width: 100%;
  overflow: hidden;
}

.mat-navbar {
  background: #0f0e13;
}

.mat-toolbar-row {
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #293b7b;
  border-radius: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.f-12 {
  font-size: 12px;
}

.p-relative {
  position: relative;
}
.c-pointer {
  cursor: pointer;
}
.i-icon {
  position: absolute;
  right: 12px;
  top: 12px;
}
.fnt-sm.c-blue {
  color: black !important;
  font-weight: 600;
}
img.are_you {
  width: 200px;
}
.discard-btn {
  color: #00023a !important;
  background-color: white !important;
  border-radius: 5px !important;
  border: 1px solid #00023a !important;
  padding: 10px 22px !important;
  font-weight: 700 !important;
  margin-right: 10px !important;
}

.discard-btn:hover {
  box-shadow: 0px 0px 5px #00023a !important;
}
.publish-button {
  color: white !important;
  background-color: #00023a !important;
  border-radius: 5px !important;
  padding: 10px 22px !important;
  font-weight: 700 !important;
  border: none !important;
  margin-left: 10px !important;
}

.publish-button:hover {
  box-shadow: 0px 0px 5px black !important;
}
.decline-btn {
  color: #293b7b !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #293b7b !important;
}
.decline-btn:hover {
  box-shadow: 3px 3px 5px #293b7b;
}
.accept-btn {
  background: #293b7b !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  box-shadow: 3px 3px 5px #293b7b;
}

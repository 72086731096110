.tanseed-img {
    width: 100%;
    height: 90vh;
}

.tanseed-bg {
    /* background-image: url(./public/images/modal-bg.png); */
    background: url(/public/images/modal-bg.png);
    width: 100%;
    min-height: 92vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
}

/* .tanseed-logo {
    margin: 40px 0px 0px 0px;
} */

.tanseed-bg-img {
    position: relative;
}

.tanseed-content .head {
    font-size: 72px;
    color: #FFBD4D;
    margin-top: 40px;
}

.tanseed-content {
    text-align: center;
}

.tanseed-content .head {
    text-shadow: 1px 3px #4f4f4f;
}

.tanseed-content p {
    font-size: 20px;
    color: white;
}

.tanseed-content h2 {
    color: white;
    font-weight: bold;
    letter-spacing: 6px;
}

.date {
    font-size: 24px;
    color: white;
}

.yellow-clr {
    color: #FFBD4D;
    font-size: 25px;
}

.yellow-color {
    color: #FFBD4D;
}

.thank {
    font-size: 20px;
    color: white;
    margin: 35px 0px 0px 0px;
}


.reg {
    font-size: 20px;
    color: white;
}

.signup-btn {
    border: none;
    background-color: #ffbd4d;
    color: white;
    /* padding: 3px 20px; */
    border-radius: 32px;
    font-size: 20px;
    margin: 28px 0px;   
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 5px 30px;
}

.r-dir {
    color: white;
    font-size: 18px;
}

.grn-btn {
    color: rgb(255 189 77);
    cursor: pointer;
}

.tanseedlink {
    color: rgb(255 189 77) !important;
}

.tanseedlink:hover {
    color: white !important;
}
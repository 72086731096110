.home_main_div {
    padding-top: 60px;
    min-height: 100vh;
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.align-item-center {
    align-items: center;
}
.c-orange{
    color: orangered !important;
}

.c-blue {
    color: #007bff !important;
}

.c-green {
    color: green;
}
.c-red {
    color: red;
}
.p-relative {
    position: relative;
}

h1 {
    font-size: clamp(12px, 4vw, 34px);
}
h2 {
    font-size: clamp(12px, 4vw, 32px);
}
h3 {
    font-size: clamp(12px, 4vw, 26px);
}
h4 {
    font-size: clamp(12px, 4vw, 22px);
}
h5 {
    font-size: clamp(12px, 4vw, 20px);
}
h6 {
    font-size: clamp(12px, 4vw, 18px);
}
.font-sm {
    font-size: 12px;
}

.font-lg {
    font-size: 16px;
}

/* .scroll_up{
    display: block;
}
.scroll_down{
    display: none;
} */
.d_block_logo {
    display: block !important;
}

/* .loader_div {
    width: 100%;
    height: 100vh;
    position: relative;
    background: #293b7b;
} */

/* .loader_css {
    position: absolute;
    top: 35%;
} */
.pdf-right-arrow{
    right: 23%;
    position: absolute;
    top: 50%;
    transform: translate(-24px, -50%); 
    z-index:1;
}
.pdf-left-arrow{
    left: 27%;
    position: absolute;
    top: 50%;
    transform: translate(-24px, -50%); 
    z-index:1;
}
.full-pdf{
    height: 450px;
    width: 100%;
    position: relative;
}
@media (max-width:768px){
    .full-pdf{
        height: 330px;
        width: 100%;
        position: relative;
    }
    .pdf-right-arrow{
        right: 10%;
        position: absolute;
        top: 50%;
        transform: translate(-24px, -50%); 
        z-index:1;
    }
    .pdf-left-arrow{
        left: 15%;
        position: absolute;
        top: 50%;
        transform: translate(-24px, -50%); 
        z-index:1;
    }
}
@media (max-width:425px){
    .full-pdf{
        height: 200px;
        width: 100%;
        position: relative;
    }
    .pdf-right-arrow{
        right: 5%;
        position: absolute;
        top: 50%;
        transform: translate(-24px, -50%); 
        z-index:1;
    }
}
@media (max-width:375px){
    .full-pdf{
        height: 160px;
        width: 100%;
        position: relative;
    }
    .pdf-left-arrow{
        left: 20%;
        position: absolute;
        top: 50%;
        transform: translate(-24px, -50%); 
        z-index:1;
    }
}
section.log-section {
    padding: 7em 0;
}

.text-wrap,
.login-wrap {
    width: 50%;
    /* margin: 0px 5px; */
}

img.signin_logo {
    /* width: 300px; */
    margin-bottom: 25px;
}

p.set-fnt {
    font-size: 25px;
}

.text-wrap-signup,
.text-wrap {
    color: #fff;
    background: #253B80;
}

.btn.btn-white.btn-outline-white {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    border-radius: 25px;
}

.btn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 10px 20px !important;
    border-radius: 50px;
}

.white_logo {
    width: 150px;
    margin-bottom: 25px;
}

@media (max-width: 991.98px) {

    .text-wrap,
    .login-wrap {
        width: 100%;
    }
}
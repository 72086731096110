.submenu_div {
    box-shadow: 0 2px 4px rgba(0, 0, 20, .08), 0 1px 2px rgba(0, 0, 20, .08);
    background: white;
}

.nav-link {
    color: black !important;
}

.nav-link.active {
    color: #19c1dc !important;
    border-bottom: 1px solid #19c1dc;
}


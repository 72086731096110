section.signup-section {
    /* padding: 55px 0px 55px 0px; */
    padding: 4em 0;
}

.text-wrap-signup {
    width: 35%;
}

.signup-wrap {
    width: 80%;
    /* height: 103vh; */
    border-radius: 0px 24px 24px 0px;
}

img.startup-bar {
    width: 100%;
    height: 100%;
}

@media (max-width: 991.98px) {

    .text-wrap-signup,
    .signup-wrap {
        width: 100%;
    }
    
}

@media (min-width: 992px) {
    .p-lg-5 {
        padding: 2.5rem!important;
    }
}
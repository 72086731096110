:root {
    --h3-font-family: "Poppins-Bold", Helvetica;
    --h3-font-size: 44px;
    --h3-font-style: normal;
    --h3-font-weight: 700;
    --h3-letter-spacing: -2px;
    --h3-line-height: 48px;
    --mainwhite: rgba(255, 255, 255, 1);
}

.final {
    background-color: #0d183b;
    height: 800px;
    overflow: hidden;
    width: 1440px;
}

.final .overlap {
    height: 758px;
    position: relative;
    top: 42px;
    width: 1442px;
}

.final .frame {
    height: 696px;
    left: 0;
    opacity: 0.6;
    overflow: hidden;
    position: absolute;
    top: 62px;
    width: 1442px;
}

.final .group {
    height: 1442px;
    opacity: 0.2;
}

.final .overlap-group {
    height: 696px;
    position: relative;
    width: 1440px;
}

.final .ellipse {
    height: 381px;
    left: 315px;
    position: absolute;
    top: 315px;
    width: 812px;
}

.final .img {
    height: 493px;
    left: 203px;
    position: absolute;
    top: 203px;
    width: 1037px;
}

.final .ellipse-2 {
    height: 588px;
    left: 108px;
    position: absolute;
    top: 108px;
    width: 1226px;
}

.final .ellipse-3 {
    height: 696px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
}

.final .text-wrapper {
    color: var(--mainwhite);
    font-family: var(--h3-font-family);
    font-size: var(--h3-font-size);
    font-style: var(--h3-font-style);
    font-weight: var(--h3-font-weight);
    left: 573px;
    letter-spacing: var(--h3-letter-spacing);
    line-height: var(--h3-line-height);
    position: absolute;
    top: 37px;
    white-space: nowrap;
}

.final .div {
    position: absolute;
}

.final .group-2 {
    height: 48px;
    left: 0;
    position: absolute;
    top: 0;
    width: 386px;
}

.final .allotment-to-funding {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .rectangle {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 24px;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 12px;
    width: 16px;
}

.final .group-3 {
    height: 48px;
    left: 0;
    position: absolute;
    top: 82px;
    width: 242px;
}

.final .text-wrapper-2 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .group-4 {
    height: 48px;
    left: 0;
    position: absolute;
    top: 164px;
    width: 282px;
}

.final .text-wrapper-3 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .group-5 {
    height: 48px;
    left: 0;
    position: absolute;
    top: 246px;
    width: 402px;
}

.final .text-wrapper-4 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .overlap-2 {
    position: relative;
}

.final .component {
    height: 222px;
    position: absolute;
    width: 198px;
}

.final .overlap-wrapper {
    left: 0;
    position: absolute;
    top: 0;
}

.final .overlap-3 {
    position: relative;
}

.final .overlap-group-wrapper {
    position: absolute;
}

.final .overlap-group-2 {
    position: relative;
}

.final .group-6 {
    height: 480px;
    position: absolute;
    width: 480px;
}

.final .ellipse-4 {
    border: 6px solid;
    border-color: #ffffff;
    border-radius: 176.5px;
    height: 353px;
    position: absolute;
    width: 353px;
}

.final .ellipse-5 {
    border: 2px solid;
    border-color: #0071ed1a;
    border-radius: 196px;
    height: 392px;
    position: absolute;
    width: 392px;
}

.final .ellipse-6 {
    height: 373px;
    position: absolute;
}

.final .frame-wrapper {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    position: absolute;
    width: 68px;
}

.final .div-wrapper {
    align-items: center;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-5 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-7 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    position: absolute;
    width: 68px;
}

.final .frame-2 {
    align-items: center;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-6 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-8 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    position: absolute;
    width: 68px;
}

.final .frame-3 {
    align-items: center;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-7 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-9 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    position: absolute;
    width: 68px;
}

.final .frame-4 {
    align-items: center;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-8 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-10 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    position: absolute;
    width: 68px;
}

.final .frame-5 {
    align-items: center;
    background-color: #171d2e;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    opacity: 0.5;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-9 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-11 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    position: absolute;
    width: 68px;
}

.final .frame-6 {
    align-items: center;
    background-color: #171d2e;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-10 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-12 {
    height: 133px;
    left: 1165px;
    position: absolute;
    top: 0;
    width: 275px;
}

.final .group-13 {
    position: absolute;
}

.final .overlap-4 {
    position: relative;
}

.final .group-14 {
    height: 466px;
    left: 28px;
    position: absolute;
    top: 6px;
    transform: rotate(1.47deg);
    width: 466px;
}

.final .overlap-group-3 {
    height: 492px;
    left: -13px;
    position: relative;
    top: -13px;
    width: 492px;
}

.final .group-15 {
    height: 480px;
    left: 6px;
    position: absolute;
    top: 6px;
    transform: rotate(-1.47deg);
    width: 480px;
}

.final .ellipse-7 {
    border: 6px solid;
    border-color: #ffffff;
    border-radius: 176.5px;
    height: 353px;
    left: 70px;
    position: absolute;
    top: 69px;
    width: 353px;
}

.final .ellipse-8 {
    border: 2px solid;
    border-color: #0071ed1a;
    border-radius: 196px;
    height: 392px;
    left: 50px;
    position: absolute;
    top: 50px;
    width: 392px;
}

.final .ellipse-9 {
    height: 373px;
    left: 60px;
    position: absolute;
    top: 61px;
    transform: rotate(-1.47deg);
    width: 191px;
}

.final .group-16 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    left: 0;
    position: absolute;
    top: 166px;
    width: 68px;
}

.final .frame-7 {
    align-items: center;
    background-color: #222222;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    opacity: 0.5;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-11 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-17 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    left: 380px;
    position: absolute;
    top: 385px;
    width: 68px;
}

.final .text-wrapper-12 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-18 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    left: 457px;
    position: absolute;
    top: 164px;
    width: 68px;
}

.final .text-wrapper-13 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-19 {
    background-color: #0d183b;
    border-radius: 34px;
    height: 68px;
    left: 85px;
    position: absolute;
    top: 389px;
    width: 68px;
}

.final .text-wrapper-14 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .rectangle-2 {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 24px;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 11px;
    width: 16px;
}

.final .text-wrapper-15 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 477px;
}

.final .group-20 {
    height: 48px;
    position: relative;
    width: 384px;
}

.final .allotment-to-funding-wrapper {
    height: 48px;
    left: 45px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 341px;
}

.final .allotment-to-funding-2 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 0;
    letter-spacing: -2px;
    line-height: 48px;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .group-21 {
    height: 48px;
    position: relative;
    width: 242px;
}

.final .text-wrapper-16 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .group-22 {
    height: 198px;
    margin-right: -2px;
    position: relative;
    width: 647px;
}

.final .overlap-group-4 {
    height: 198px;
    position: relative;
    width: 645px;
}

.final .rectangle-3 {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 106px;
    left: 0;
    position: absolute;
    top: 67px;
    width: 19px;
}

.final .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    left: 9px;
    position: absolute;
    top: 0;
}

.final .text-wrapper-17 {
    color: #57e4ff;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .rectangle-4 {
    border: 0.6px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(235.88, 229.98, 229.98), rgba(255, 255, 255, 0)) 1;
    border-radius: 15px;
    height: 154px;
    position: relative;
    width: 636px;
}

.final .group-23 {
    height: 48px;
    left: 46px;
    position: absolute;
    top: 50px;
    width: 299px;
}

.final .text-wrapper-18 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 32px;
    font-weight: 500;
    left: 0;
    letter-spacing: -2px;
    line-height: 48px;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .p {
    color: #ffffff;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 46px;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: absolute;
    top: 108px;
    width: 570px;
}

.final .group-24 {
    height: 48px;
    position: relative;
    width: 402px;
}

.final .text-wrapper-19 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .rectangle-5 {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 106px;
    left: 0;
    position: absolute;
    top: 135px;
    width: 19px;
}

.final .group-25 {
    height: 48px;
    position: absolute;
}

.final .group-26 {
    left: 45px;
    opacity: 0.3;
    position: absolute;
    top: 0;
}

.final .group-27 {
    position: absolute;
}

.final .group-28 {
    height: 46px;
    position: relative;
    width: 524px;
}

.final .text-wrapper-20 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .group-29 {
    position: absolute;
    width: 402px;
}

.final .text-wrapper-21 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .group-30 {
    height: 46px;
    position: absolute;
    top: 0;
    width: 522px;
}

.final .group-31 {
    height: 46px;
    left: 45px;
    position: absolute;
    top: 0;
    width: 479px;
}

.final .text-wrapper-22 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 0;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 477px;
}

.final .text-wrapper-23 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 45px;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .frame-9 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
}

.final .group-wrapper {
    height: 48px;
    position: relative;
    width: 280px;
}

.final .group-32 {
    height: 48px;
    position: relative;
}

.final .group-33 {
    height: 48px;
    left: 45px;
    position: absolute;
    top: 0;
    width: 237px;
}

.final .text-wrapper-24 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 0;
    letter-spacing: -2px;
    line-height: 48px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.final .frame-10 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.final .group-34 {
    height: 206px;
    margin-right: -4px;
    position: relative;
    width: 649px;
}

.final .overlap-group-5 {
    height: 206px;
    position: relative;
    width: 645px;
}

.final .rectangle-6 {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 106px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 19px;
}

.final .rectangle-7 {
    border: 0.6px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(235.88, 229.98, 229.98), rgba(255, 255, 255, 0)) 1;
    border-radius: 15px;
    height: 162px;
    position: relative;
    width: 636px;
}

.final .text-wrapper-25 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 32px;
    font-weight: 500;
    left: 46px;
    letter-spacing: -2px;
    line-height: 48px;
    position: absolute;
    top: 56px;
    white-space: nowrap;
}

.final .text-wrapper-26 {
    color: #ffffff;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 46px;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: absolute;
    top: 114px;
    width: 570px;
}

.final .overlap-group-6 {
    height: 195px;
    position: relative;
}

.final .group-35 {
    height: 195px;
    left: 0;
    position: absolute;
    top: 0;
    width: 645px;
}

.final .rectangle-8 {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 105px;
    left: 0;
    position: absolute;
    top: 59px;
    width: 19px;
}

.final .text-wrapper-27 {
    color: #57e4ff;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 35.49px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: right;
    width: 76px;
}

.final .rectangle-9 {
    border: 0.6px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(235.88, 229.98, 229.98), rgba(255, 255, 255, 0)) 1;
    border-radius: 15px;
    height: 151.82px;
    position: relative;
    width: 636px;
}

.final .frame-11 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    left: 54px;
    position: absolute;
    top: 46px;
}

.final .text-wrapper-28 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.final .text-wrapper-29 {
    color: #ffffff;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: relative;
    width: 570px;
}

.final .frame-12 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    left: 9px;
    position: absolute;
    top: 71px;
}

.final .frame-13 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    left: 54px;
    position: absolute;
    top: 123px;
}

.final .text-wrapper-30 {
    color: #ffffff;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: relative;
    width: 538px;
}

.final .ellipse-10 {
    background-color: #152043;
    border-radius: 105px/97px;
    height: 194px;
    position: absolute;
    width: 210px;
}

.final .group-36 {
    position: absolute;
}

.final .group-37 {
    height: 111px;
    left: 0;
    position: absolute;
    top: 647px;
    width: 142px;
}

.final .group-38 {
    background-color: #0d183b;
    border-radius: 34px;
    box-shadow: 2px 4px 40px #57e4ff;
    height: 68px;
    left: 335px;
    position: absolute;
    top: 161px;
    width: 68px;
}

.final .frame-14 {
    align-items: center;
    background-color: #0071ed;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 48px;
    justify-content: center;
    left: 10px;
    padding: 8px;
    position: relative;
    top: 10px;
    width: 48px;
}

.final .text-wrapper-31 {
    color: var(--mainwhite);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    text-align: right;
    width: fit-content;
}

.final .group-39 {
    height: 195px;
    left: 726px;
    position: absolute;
    top: 105px;
    width: 647px;
}

.final .overlap-5 {
    height: 154px;
    left: 0;
    position: absolute;
    top: 41px;
    width: 645px;
}

.final .rectangle-10 {
    background-color: #57e4ff;
    border-radius: 20px;
    height: 106px;
    left: 0;
    position: absolute;
    top: 24px;
    width: 19px;
}

.final .rectangle-11 {
    border: 0.6px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(235.88, 229.98, 229.98), rgba(255, 255, 255, 0)) 1;
    border-radius: 15px;
    height: 154px;
    left: 9px;
    position: absolute;
    top: 0;
    width: 636px;
}

.final .group-40 {
    height: 104px;
    left: 54px;
    position: absolute;
    top: 21px;
    width: 538px;
}

.final .group-41 {
    height: 104px;
    position: relative;
    width: 542px;
}

.final .text-wrapper-32 {
    color: #ffffff;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.6;
    position: absolute;
    top: 58px;
    width: 538px;
}

.final .text-wrapper-33 {
    color: #57e4ff;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 34px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 0;
}

.final .ellipse-11 {
    background-color: #152043;
    border-radius: 105px/97px;
    height: 194px;
    left: 278px;
    position: absolute;
    top: 352px;
    width: 210px;
}

.final .component-2 {
    height: 226px;
    left: 270px;
    position: absolute;
    top: 342px;
    width: 198px;
}

.final .component-188 {
    height: 514px;
    left: 130px;
    top: 165px;
    width: 498px;
}

.final .component-186 {
    height: 294px;
    left: 735px;
    top: 334px;
    width: 400px;
}

.final .component-189 {
    height: 529px;
    left: 134px;
    top: 165px;
    width: 494px;
}

.final .component-187 {
    height: 514px;
    left: 124px;
    top: 165px;
    width: 504px;
}

.final .component-190 {
    height: 499px;
    left: 120px;
    top: 171px;
    width: 498px;
}

.final .property-1-21-component-188 {
    left: 129px;
    top: 514px;
}

.final .property-1-21-component-186 {
    left: 125px;
    top: 297px;
}

.final .property-1-21-component-189 {
    left: 125px;
    top: 297px;
}

.final .property-1-21-component-187 {
    left: 125px;
    top: 297px;
}

.final .property-1-21-component-190 {
    left: 125px;
    top: 297px;
}

.final .property-1-22-component-188 {
    height: 503px;
    left: 733px;
    top: 146px;
    width: 645px;
}

.final .property-1-22-component-186 {
    height: 478px;
    left: 108px;
    top: 195px;
    width: 525px;
}

.final .property-1-22-component-189 {
    height: 516px;
    left: 740px;
    top: 169px;
    width: 645px;
}

.final .property-1-22-component-187 {
    height: 511px;
    left: 733px;
    top: 146px;
    width: 645px;
}

.final .property-1-22-component-190 {
    height: 524px;
    left: 742px;
    top: 146px;
    width: 645px;
}

.final .property-1-33-component-188 {
    left: 281px;
    top: 390px;
}

.final .property-1-33-component-186 {
    left: 271px;
    top: 349px;
}

.final .property-1-33-component-189 {
    left: 264px;
    top: 339px;
}

.final .property-1-33-component-187 {
    left: 271px;
    top: 349px;
}

.final .property-1-33-component-190 {
    left: 270px;
    top: 350px;
}

.final .property-1-34-component-188 {
    height: 177px;
    left: 275px;
    top: 343px;
    width: 205px;
}

.final .property-1-34-component-186 {
    height: 161px;
    left: 282px;
    top: 365px;
    width: 188px;
}

.final .property-1-34-component-189 {
    height: 167px;
    left: 292px;
    top: 357px;
    width: 160px;
}

.final .property-1-34-component-187 {
    height: 161px;
    left: 282px;
    top: 365px;
    width: 188px;
}

.final .property-1-34-component-190 {
    height: 187px;
    left: 274px;
    top: 337px;
    width: 187px;
}

.final .div.component-188 .overlap-2 {
    height: 514px;
}

.final .div.component-186 .overlap-2 {
    height: 514px;
}

.final .div.component-189 .overlap-2 {
    height: 529px;
}

.final .div.component-187 .overlap-2 {
    height: 514px;
}

.final .div.component-190 .overlap-2 {
    height: 499px;
}

.final .div.component-188 .component {
    left: 140px;
    top: 174px;
}

.final .div.component-186 .component {
    left: 136px;
    top: 176px;
}

.final .div.component-189 .component {
    left: 136px;
    top: 176px;
}

.final .div.component-187 .component {
    left: 146px;
    top: 176px;
}

.final .div.component-190 .component {
    left: 150px;
    top: 167px;
}

.final .div.component-188 .overlap-wrapper {
    height: 514px;
    width: 498px;
}

.final .div.component-186 .overlap-wrapper {
    height: 514px;
    width: 504px;
}

.final .div.component-189 .overlap-wrapper {
    height: 502px;
    width: 494px;
}

.final .div.component-187 .overlap-wrapper {
    height: 514px;
    width: 504px;
}

.final .div.component-190 .overlap-wrapper {
    height: 499px;
    width: 488px;
}

.final .div.component-188 .overlap-3 {
    height: 514px;
}

.final .div.component-186 .overlap-3 {
    height: 514px;
}

.final .div.component-189 .overlap-3 {
    height: 502px;
}

.final .div.component-187 .overlap-3 {
    height: 514px;
}

.final .div.component-190 .overlap-3 {
    height: 499px;
}

.final .div.component-188 .overlap-group-wrapper {
    height: 466px;
    left: 6px;
    top: 36px;
    transform: rotate(1.47deg);
    width: 466px;
}

.final .div.component-186 .overlap-group-wrapper {
    height: 466px;
    left: 6px;
    top: 36px;
    width: 466px;
}

.final .div.component-189 .overlap-group-wrapper {
    height: 466px;
    left: 2px;
    top: 36px;
    width: 466px;
}

.final .div.component-187 .overlap-group-wrapper {
    height: 478px;
    left: 6px;
    top: 30px;
    transform: rotate(1.47deg);
    width: 478px;
}

.final .div.component-190 .overlap-group-wrapper {
    height: 466px;
    left: 16px;
    top: 27px;
    transform: rotate(1.47deg);
    width: 466px;
}

.final .div.component-188 .overlap-group-2 {
    height: 492px;
    left: -13px;
    top: -13px;
    width: 492px;
}

.final .div.component-186 .overlap-group-2 {
    height: 492px;
    left: -7px;
    top: -7px;
    width: 492px;
}

.final .div.component-189 .overlap-group-2 {
    height: 480px;
    left: -7px;
    top: -7px;
    width: 480px;
}

.final .div.component-187 .overlap-group-2 {
    height: 492px;
    left: -7px;
    top: -7px;
    width: 492px;
}

.final .div.component-190 .overlap-group-2 {
    height: 492px;
    left: -13px;
    top: -13px;
    width: 492px;
}

.final .div.component-188 .group-6 {
    left: 6px;
    top: 6px;
    transform: rotate(-1.47deg);
}

.final .div.component-186 .group-6 {
    left: 6px;
    top: 6px;
}

.final .div.component-189 .group-6 {
    left: 0;
    top: 0;
}

.final .div.component-187 .group-6 {
    left: 6px;
    top: 6px;
    transform: rotate(-1.47deg);
}

.final .div.component-190 .group-6 {
    left: 6px;
    top: 6px;
    transform: rotate(-1.47deg);
}

.final .div.component-188 .ellipse-4 {
    left: 70px;
    top: 69px;
}

.final .div.component-186 .ellipse-4 {
    left: 70px;
    top: 69px;
}

.final .div.component-189 .ellipse-4 {
    left: 63px;
    top: 63px;
}

.final .div.component-187 .ellipse-4 {
    left: 70px;
    top: 69px;
}

.final .div.component-190 .ellipse-4 {
    left: 69px;
    top: 69px;
}

.final .div.component-188 .ellipse-5 {
    left: 50px;
    top: 50px;
}

.final .div.component-186 .ellipse-5 {
    left: 50px;
    top: 50px;
}

.final .div.component-189 .ellipse-5 {
    left: 43px;
    top: 44px;
}

.final .div.component-187 .ellipse-5 {
    left: 50px;
    top: 50px;
}

.final .div.component-190 .ellipse-5 {
    left: 49px;
    top: 50px;
}

.final .div.component-188 .ellipse-6 {
    left: 60px;
    top: 61px;
    transform: rotate(-1.47deg);
    width: 191px;
}

.final .div.component-186 .ellipse-6 {
    left: 60px;
    top: 61px;
    width: 191px;
}

.final .div.component-189 .ellipse-6 {
    left: 53px;
    top: 53px;
    width: 186px;
}

.final .div.component-187 .ellipse-6 {
    left: 60px;
    top: 61px;
    transform: rotate(-1.47deg);
    width: 191px;
}

.final .div.component-190 .ellipse-6 {
    left: 59px;
    top: 61px;
    transform: rotate(-1.47deg);
    width: 191px;
}

.final .div.component-188 .frame-wrapper {
    left: 284px;
    top: 446px;
}

.final .div.component-186 .frame-wrapper {
    left: 20px;
    top: 367px;
}

.final .div.component-189 .frame-wrapper {
    left: 426px;
    top: 217px;
}

.final .div.component-187 .frame-wrapper {
    left: 20px;
    top: 367px;
}

.final .div.component-190 .frame-wrapper {
    box-shadow: 2px 4px 40px #57e4ff;
    left: 215px;
    top: 0;
}

.final .div.component-188 .div-wrapper {
    background-color: #222222;
    opacity: 0.5;
}

.final .div.component-186 .div-wrapper {
    background-color: #222222;
}

.final .div.component-189 .div-wrapper {
    background-color: #222222;
    opacity: 0.5;
}

.final .div.component-187 .div-wrapper {
    background-color: #222222;
    opacity: 0.5;
}

.final .div.component-190 .div-wrapper {
    background-color: #0071ed;
}

.final .div.component-188 .group-7 {
    box-shadow: 2px 4px 40px #57e4ff;
    left: 222px;
    top: 0;
}

.final .div.component-186 .group-7 {
    left: 436px;
    top: 206px;
}

.final .div.component-189 .group-7 {
    left: 0;
    top: 113px;
}

.final .div.component-187 .group-7 {
    left: 436px;
    top: 206px;
}

.final .div.component-190 .group-7 {
    left: 48px;
    top: 389px;
}

.final .div.component-188 .frame-2 {
    background-color: #0071ed;
}

.final .div.component-186 .frame-2 {
    background-color: #121b34;
}

.final .div.component-189 .frame-2 {
    background-color: #121b34;
}

.final .div.component-187 .frame-2 {
    background-color: #222222;
    opacity: 0.5;
}

.final .div.component-190 .frame-2 {
    background-color: #121b34;
}

.final .div.component-189 .text-wrapper-6 {
    opacity: 0.5;
}

.final .div.component-190 .text-wrapper-6 {
    opacity: 0.5;
}

.final .div.component-188 .group-8 {
    left: 40px;
    top: 77px;
}

.final .div.component-186 .group-8 {
    left: 211px;
    top: 0;
}

.final .div.component-189 .group-8 {
    left: 0;
    top: 364px;
}

.final .div.component-187 .group-8 {
    box-shadow: 2px 4px 40px #57e4ff;
    left: 211px;
    top: 0;
}

.final .div.component-190 .group-8 {
    left: 343px;
    top: 427px;
}

.final .div.component-188 .frame-3 {
    background-color: #121b34;
}

.final .div.component-186 .frame-3 {
    background-color: #121b34;
}

.final .div.component-189 .frame-3 {
    background-color: #121b34;
}

.final .div.component-187 .frame-3 {
    background-color: #0071ed;
}

.final .div.component-190 .frame-3 {
    background-color: #121b34;
}

.final .div.component-188 .text-wrapper-7 {
    opacity: 0.5;
}

.final .div.component-189 .text-wrapper-7 {
    opacity: 0.5;
}

.final .div.component-190 .text-wrapper-7 {
    opacity: 0.5;
}

.final .div.component-188 .group-9 {
    left: 430px;
    top: 216px;
}

.final .div.component-186 .group-9 {
    left: 296px;
    top: 444px;
}

.final .div.component-189 .group-9 {
    box-shadow: 2px 4px 40px #57e4ff;
    left: 201px;
    top: 0;
}

.final .div.component-187 .group-9 {
    left: 296px;
    top: 444px;
}

.final .div.component-190 .group-9 {
    left: 0;
    top: 145px;
}

.final .div.component-188 .frame-4 {
    background-color: #222222;
    opacity: 0.5;
}

.final .div.component-186 .frame-4 {
    background-color: #222222;
}

.final .div.component-189 .frame-4 {
    background-color: #0071ed;
}

.final .div.component-187 .frame-4 {
    background-color: #222222;
    opacity: 0.5;
}

.final .div.component-190 .frame-4 {
    background-color: #171d2e;
}

.final .div.component-190 .text-wrapper-8 {
    opacity: 0.5;
}

.final .div.component-188 .group-10 {
    left: 248px;
    top: 461px;
}

.final .div.component-186 .group-10 {
    left: 248px;
    top: 461px;
}

.final .div.component-189 .group-10 {
    left: 248px;
    top: 461px;
}

.final .div.component-187 .group-10 {
    left: 248px;
    top: 461px;
}

.final .div.component-190 .group-10 {
    left: 430px;
    top: 123px;
}

.final .group-11.property-1-21-component-188 .frame-6 {
    opacity: 0.5;
}

.final .group-11.property-1-21-component-187 .text-wrapper-10 {
    opacity: 0.5;
}

.final .group-13.property-1-22-component-188 .frame-15 {
    height: 503px;
    position: relative;
}

.final .group-13.property-1-22-component-186 .frame-15 {
    height: 478px;
}

.final .group-13.property-1-22-component-189 .frame-15 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    height: 478px;
    position: relative;
}

.final .group-13.property-1-22-component-187 .frame-15 {
    height: 511px;
    position: relative;
}

.final .group-13.property-1-22-component-190 .frame-15 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    height: 478px;
    position: relative;
}

.final .group-13.property-1-22-component-188 .overlap-4 {
    height: 478px;
    width: 525px;
}

.final .group-13.property-1-22-component-186 .overlap-4 {
    height: 478px;
    width: 525px;
}

.final .group-13.property-1-22-component-189 .overlap-4 {
    height: 46px;
    width: 524px;
}

.final .group-13.property-1-22-component-187 .overlap-4 {
    height: 478px;
    width: 525px;
}

.final .group-13.property-1-22-component-190 .overlap-4 {
    height: 478px;
    width: 525px;
}

.final .group-13.property-1-22-component-188 .group-42 {
    height: 503px;
    left: 9px;
    position: absolute;
    top: 0;
    width: 522px;
}

.final .group-13.property-1-22-component-186 .group-42 {
    height: 126px;
    left: 9px;
    position: absolute;
    width: 522px;
}

.final .group-13.property-1-22-component-189 .group-42 {
    height: 126px;
    left: 9px;
    position: absolute;
    width: 522px;
}

.final .group-13.property-1-22-component-187 .group-42 {
    height: 511px;
    left: 0;
    position: absolute;
    top: 0;
    width: 531px;
}

.final .group-13.property-1-22-component-190 .group-42 {
    height: 126px;
    left: 9px;
    position: relative;
    width: 522px;
}

.final .group-13.property-1-22-component-188 .group-25 {
    left: 0;
    top: 80px;
    width: 384px;
}

.final .group-13.property-1-22-component-186 .group-25 {
    left: 0;
    top: 80px;
    width: 384px;
}

.final .group-13.property-1-22-component-189 .group-25 {
    left: 0;
    top: 80px;
    width: 384px;
}

.final .group-13.property-1-22-component-187 .group-25 {
    left: 9px;
    top: 299px;
    width: 242px;
}

.final .group-13.property-1-22-component-190 .group-25 {
    left: 0;
    top: 78px;
    width: 384px;
}

.final .group-13.property-1-22-component-188 .group-26 {
    height: 48px;
    width: 341px;
}

.final .group-13.property-1-22-component-187 .group-26 {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 48px;
    white-space: nowrap;
}

.final .group-13.property-1-22-component-190 .group-26 {
    height: 48px;
    width: 341px;
}

.final .group-13.property-1-22-component-188 .group-27 {
    height: 47px;
    left: 0;
    top: 375px;
    width: 282px;
}

.final .group-13.property-1-22-component-186 .group-27 {
    height: 46px;
    left: 0;
    top: 0;
    width: 282px;
}

.final .group-13.property-1-22-component-189 .group-27 {
    height: 46px;
    left: 0;
    top: 0;
    width: 282px;
}

.final .group-13.property-1-22-component-187 .group-27 {
    height: 48px;
    left: 9px;
    top: 381px;
    width: 282px;
}

.final .group-13.property-1-22-component-190 .group-27 {
    height: 46px;
    left: 0;
    top: 0;
    width: 522px;
}

.final .group-13.property-1-22-component-188 .text-wrapper-20 {
    width: 235px;
}

.final .group-13.property-1-22-component-188 .group-29 {
    height: 47px;
    left: 0;
    top: 456px;
}

.final .group-13.property-1-22-component-186 .group-29 {
    height: 48px;
    left: 9px;
    top: 463px;
}

.final .group-13.property-1-22-component-189 .group-29 {
    height: 48px;
    left: 9px;
    top: 463px;
}

.final .group-13.property-1-22-component-187 .group-29 {
    height: 48px;
    left: 9px;
    top: 463px;
}

.final .group-13.property-1-22-component-190 .group-29 {
    height: 48px;
    left: 9px;
    top: 463px;
}

.final .group-13.property-1-22-component-188 .text-wrapper-21 {
    width: 355px;
}

.final .group-13.property-1-22-component-188 .group-30 {
    left: 0;
}

.final .group-13.property-1-22-component-186 .group-30 {
    left: 9px;
}

.final .group-13.property-1-22-component-189 .group-30 {
    left: 9px;
}

.final .group-13.property-1-22-component-187 .group-30 {
    left: 9px;
}

.final .group-13.property-1-22-component-190 .group-30 {
    left: 9px;
}

.final .group-13.property-1-22-component-188 .frame-15 {
    height: 195px;
    left: 0;
    position: absolute;
    top: 154px;
    width: 645px;
}

.final .group-13.property-1-22-component-186 .frame-15 {
    position: relative;
}

.final .group-13.property-1-22-component-189 .frame-15 {
    position: relative;
}

.final .group-13.property-1-22-component-187 .frame-15 {
    position: relative;
}

.final .group-13.property-1-22-component-190 .frame-15 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
}
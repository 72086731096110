.modal1 {
  background: url(/public/images/modal-bg.png);
  background-repeat: no-repeat;
  color: white;
  background-size: cover;
}

.yellow {
  /* background-color: #FFBD4D !important;
    border-radius: 25px !important;
    color: white !important;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* border-radius: 31px !important; */
  /* padding: 5px 10px 0px 10px !important; */
  border: none;
  background-color: #ffbd4d;
  color: white;
  /* padding: 3px 20px; */
  border-radius: 32px;
  font-size: 20px;
  margin: 28px 0px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 30px;
}

.yellow:hover {
  background-color: #ffbd4d !important;
}

.head {
  color: #ffbd4d;
  font-size: 50px;
}

.white-clr {
  color: white;
}

.yellow-clr {
  color: #ffbd4d;
  font-size: 17px !important;
}

.date {
  font-size: 17px !important;
  color: white;
}

/* .css-ypiqx9-MuiDialogContent-root {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 5px 24px 0px 24px !important;
} */

.white-btn {
  background-color: white !important;
  color: #1c57a6 !important;
  border-radius: 25px !important;
}

.close-btn {
  float: right;
  cursor: pointer;
}

img.logo_img {
    width: 160px;
    height: 65px;
    object-fit: contain;
    padding: 5px;
}

.logo_tanfund_img {
    width: 110px;
    height: 65px;
    object-fit: contain;
    padding: 5px;
    margin-left: 20px;
}

.logo_sml {
    width: 60px;
    height: 65px;
    object-fit: contain;
    padding: 5px;
    margin-left: 10px;
}

.css-yde5ib-MuiButtonBase-root-MuiIconButton-root {
    display: block;
    color: white !important;
}

span.username {
    border-left: 1px solid gainsboro;
    padding-left: 15px;
}
span.username1 {
    /* border-left: 1px solid gainsboro; */
    padding-left: 15px;
}

.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
    box-shadow: none !important;
}
.link_btn.active {
    color: #FCAD17 !important;
}
/* .css-1kkpk3h-MuiButtonBase-root-MuiButton-root {
    color: black !important;
} */
/* span.username {
    color: black;
} */

/* .c-white {
   
} */
.menuBtn {
    margin: 5px 20px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
}

.menuBtn a {
    color: white !important;
    margin-left: -28px;
}

li a {
    color: black !important;
}

.login_btn {
    color: #0F1833 !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    border-radius: 8px !important;
    background: #FCAD17 !important;
    padding: 8px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px;
    width: 111px;
    font-weight: 600 !important;
}

.login_btn:hover {
    background: white !important;
    color: #FCAD17 !important;
}

.menuBtn:hover a {
    color: #FCAD17 !important;
}

/* button
.MuiButtonBase-root
.MuiIconButton-root
.MuiIconButton-sizeLarge {
    color: white !important;
} */
.NavtabMenu {
    color: white !important;
}

/* button
.MuiButtonBase-root 
.MuiButton-root 
.MuiButton-text 
.MuiButton-textPrimary 
.MuiButton-sizeMedium 
.MuiButton-textSizeMedium 
.MuiButton-root 
.MuiButton-text 
.MuiButton-textPrimary 
.MuiButton-sizeMedium 
.MuiButton-textSizeMedium {
    background: #253B80 !important;
    color: white;
    box-shadow: none !important;
    padding: 15px;
} */

.toptotop {
    box-shadow: 0px 0px 5px black !important;
}

@media only screen and (max-width:575px) {
    img.logo_img {
        width: 125px;
        height: 65px;
        object-fit: contain;
        padding: 5px;
    }
    .logo_sml{
        margin-left: 0;
    }
}
.bg-body {
    background-color: #F8FAFF;
}

.fund-bg-img {
    width: 100%;
}

.funding-bg {
    position: relative;
}

.fund-partners-heading {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    right: 0;
    justify-content: center;
}

.fund-partners-heading h3 {
    color: white;
    margin-top: 50px;
}

.funding-filter {
    display: flex;
    justify-content: space-between;
    margin: 20px 15px;
}

.n-active {
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
    padding: 0px 15px;
    border-radius: 15px;
    font-size: 15px;
    cursor: pointer;
    font-family: Poppins !important;
}

.active-span {
    background-color: #152148;
    color: white;
}

.img-card1 {
    background-color: #DEE7FF;
    padding: 20px;
    min-height: 350px;
}

.span-tag span {
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
    background-color: white;
    padding: 0px 12px;
    border-radius: 15px;
    font-size: 14px;
    margin: 5px;
    display: inline-table;
}

.img-card {
    height: 20vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fund-card {
    margin-top: 100px;
}

.less {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.img-card img {
    width: 145px;
    height: 110px;
    object-fit: contain;
}

.img-c-bg {
    margin-bottom: 20px;
}

.img-c-bg1 {
    margin-bottom: 200px;
}

.page_div_investor {
    display: flex;
    width: 100%;
    justify-content: center;
}

.fund-card {
    margin-bottom: 50px;
}

.search-box {
    background-color: white;
    width: 55%;
    height: 11vh;
    border-radius: 10px;
    padding: 16px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
}

input.fund-input {
    border: none;
    width: 40%;
    position: absolute;
    height: 7vh;
    margin-left: 23px;
    padding: 6px;
    outline: none !important;
}

.css-zddlty-MuiButtonBase-root-MuiButton-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin-top: 12px !important;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: black !important;
    background-color: transparent !important;
    /* box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12); */
    box-shadow: none;
}

.css-zddlty-MuiButtonBase-root-MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: transparent !important;
    box-shadow: none !important;
}

.search-btn {
    background-color: #253B80;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    margin-left: 20px;
}

.nodata_tag {
    padding: 50px;
    text-align: center;
    color: black;
}

img.no_data_logo {
    width: 350px;
    margin-bottom: 25px;
}

.drop-btn {
    display: flex;
}

.all-btn {
    position: relative;
    border-left: 0.5px solid #d9d9d9;
    border-right: 0.5px solid #d9d9d9;
}

.cat-tit {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    margin: auto;
    color: #c7c7c7;
}

@media (max-width: 1199px) {
    .img-card1 {
        height: auto;
    }

    .funding-filter {
        margin: 20px 0px 0px 0px;
    }

    .span-tag span {
        border: 1px solid #9e9e9e;
        color: #9e9e9e;
        background-color: white;
        padding: 0px 8px;
        border-radius: 15px;
        margin-right: 4px;
        display: inline-table;
    }

    .search-box {
        background-color: white;
        width: 63%;
        height: 11vh;
        border-radius: 10px;
        padding: 11px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 991px) {
    .img-c-bg1 {
        margin-bottom: 75px;
    }

    .funding-filter {
        display: block;
        margin: 21px 48px;
    }

    .n-active {
        display: inline-block;
    }

    .n-active {
        display: inline-block;
        margin: 5px;
    }
}

@media (max-width: 768px) {
    .fund-partners-heading h3 {
        color: white;
        margin-top: 26px;
        font-size: 18px;
    }

    .search-box {
        background-color: white;
        width: 73%;
        height: 9vh;
        border-radius: 10px;
        padding: 7px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
    }

    .img-card1 {
        background-color: #DEE7FF;
        padding: 20px;
        height: 45vh;
        margin-bottom: 75px;
    }
}

@media (max-width: 575px) {
    .funding-filter {
        display: inline-table;
    }

    .fund-partners-heading h3 {
        color: white;
        margin-top: 36px;
        font-size: 16px;
    }

    .fund-bg-img {
        width: 100%;
        height: 35vh;
    }

    .search-box {
        background-color: white;
        width: 82%;
        height: 9vh;
    }

    .n-active {
        display: inline-block;
        margin: 5px;
    }

    .active-span {
        background-color: #152148;
        color: white;
        padding: 3px 10px;
        border-radius: 15px;
    }
}
/* div#vertical-tabpanel-0 {
    width: 82%;
}
div#vertical-tabpanel-1 {
    width: 82%;
}
div#vertical-tabpanel-2 {
    width: 82%;
}
div#vertical-tabpanel-3 {
    width: 82%;
}
div#vertical-tabpanel-4 {
    width: 82%;
} */
.start_aligin_center{
    align-items: center;
}